<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showDetail"
        max-width="1100px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
            <v-spacer> </v-spacer>
            <v-checkbox
              :label="'Nouveau Fournisseur'"
              v-model="new_tier"
              dense
              @change="new_tier_click"
            ></v-checkbox>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12" sm="6" md="8" v-if="!new_tier">
                  <cursorselect
                    :Qsearch="tierscursor"
                    :Qresp="'tiers_cursor'"
                    :value="editedItem.tier_id"
                    :text_fields="['code_fournisseur', 'nom']"
                    :variableadd="variableadd"
                    :item_text="'tier_name'"
                    @change="tierChange"
                    :label="'Fournisseur'"
                    :key="cs"
                  >
                  </cursorselect>
                </v-col>
                <v-col cols="12" sm="6" md="8" v-if="new_tier">
                  <v-text-field
                    autocomplete="off"
                    ref="tier_name"
                    dense
                    v-model="editedItem.tier_name"
                    label="Fournisseur"
                    :readonly="readonly"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <datepicker
                    label="Date Consultation"
                    v-model="editedItem.date_const"
                    :edit="!readonly"
                  ></datepicker>
                </v-col>
                <v-col cols="12" sm="1" md="10">
                  <v-text-field
                    autocomplete="off"
                    ref="comment"
                    dense
                    v-model="editedItem.comment"
                    label="Commentaire"
                    :readonly="readonly"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-container fill-height>
              <v-layout row justify-center align-center v-if="progress">
                <v-progress-circular
                  indeterminate
                  :size="30"
                  :width="7"
                  color="purple"
                ></v-progress-circular>
              </v-layout>
            </v-container>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="save" v-if="!readonly">
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" text @click.stop="close">
              Fermer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import CREATE_CONSULTATION_TIER from "../graphql/Consult/CREATE_CONSULTATION_TIER.gql";
import UPDATE_CONSULTATION_TIER from "../graphql/Consult/UPDATE_CONSULTATION_TIER.gql";
import TIERS_CURSOR from "../graphql/Commande/TIERS_CURSOR.gql";

export default {
  components: {
    datepicker: () => import("./DatePicker.vue"),
    cursorselect: () => import("./CursorSelect.vue"),
  },
  name: "consultdetailform",
  props: {
    item: Object,
    items: Array,
    readonly: Boolean,
    showForm: Boolean,
  },

  data: () => ({
    new_tier: false,
    progress: false,
    valid: true,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    editedItem: {},
    cs: 1200,
    loading: false,
  }),

  computed: {
    variableadd() {
      let w = {
        TypeScope: 1,
        statut_id: [2, 3],
      };
      return w;
    },
    tierscursor() {
      return TIERS_CURSOR;
    },
    showDetail() {
      return this.showForm;
    },

    formTitle() {
      if (this.item)
        return this.item.id < 0
          ? "Nouveau Fournisseur "
          : "Fournisseur N° " + this.item.code_fournisseur;
      else return "";
    },
  },
  watch: {},

  created() {},
  async mounted() {
    if (this.item) {
      this.editedItem = Object.assign({}, this.item);
      if (this.editedItem.id > 0) {
        this.cs++;
        if (!this.editedItem.tier_id) {
          this.new_tier = true;
          this.new_tier_click();
        } else this.new_tier = false;
      }
    }
  },

  methods: {
    tierChange(item) {
      this.editedItem.tier_id = item;
    },
    new_tier_click() {
      if (this.new_tier) {
        this.editedItem.tier_id = null;
      } else {
        this.editedItem.tier_name = null;
      }
    },
    customFilter(item, queryText) {
      let textlist = [];
      ["code_fournisseur", "nom"].forEach((element) => {
        const text1 = item[element] ? item[element].toLowerCase() : "";
        textlist.push(text1);
      });
      const searchText = queryText.toLowerCase();
      const index = textlist.findIndex((element) => {
        if (element.includes(searchText)) {
          return true;
        }
      });
      return index !== -1;
    },

    async requette(query, v) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.loading = false;
        });
      return r;
    },

    close() {
      this.$emit("close");
    },
    async maj(query, v) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
          this.$store.dispatch("Changed", true);
          this.progress = false;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async save() {
      if (this.$refs.form.validate()) {
        let v;
        if (this.editedItem.id > 0) {
          v = {
            consultationtier: {
              id: this.editedItem.id,
              date_const: this.editedItem.date_const,
              tier_id: this.editedItem.tier_id,
              tier_name: this.editedItem.tier_name,
              comment: this.editedItem.comment,
              write_uid: this.$store.state.me.id,
            },
          };
          this.maj(UPDATE_CONSULTATION_TIER, v);
        } else {
          v = {
            consultationtier: {
              cons_id: this.item.cons_id,
              date_const: this.editedItem.date_const,
              tier_id: this.editedItem.tier_id,
              tier_name: this.editedItem.tier_name,
              comment: this.editedItem.comment,
              write_uid: this.$store.state.me.id,
              create_uid: this.$store.state.me.id,
            },
          };
          await this.maj(CREATE_CONSULTATION_TIER, v);
        }
        this.close();
      }
    },
  },
};
</script>
